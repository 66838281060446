import Vue from 'vue'
//Vue.prototype.baseUrl = '/api/';
//Vue.prototype.baseUrl2 = '/api/';//访问图片不需要index.php
//Vue.prototype.baseUrl3 = 'http://192.168.1.3:10000/';//专家模块iframe登录域名
Vue.prototype.baseUrl = '/api/public/index.php/';
Vue.prototype.baseUrl2 = '/api/public/';//访问图片不需要index.php
Vue.prototype.baseUrl3 = 'https://expert.zaototech.cn/';//专家模块iframe登录域名
//Vue.prototype.baseUrl3 = 'http://expertApi.com/';//专家模块iframe登录域名
//招标方式
Vue.prototype.way=[
	{id:1,name:'公开招标'},
	{id:2,name:'邀请招标'},
	{id:3,name:'竞争性谈判'},
	{id:4,name:'竞争性磋商'},
	{id:5,name:'单一来源采购'},
	{id:6,name:'询价'},
	{id:7,name:'自主招标'}
];
//招标分类
Vue.prototype.type=[
	{id:1,name:'服务'},
	{id:2,name:'货物'},
	{id:3,name:'工程'},
	{id:4,name:'其他'},
];
//公告类型
Vue.prototype.notice=[
	{id:1,name:'采购公告'},
	{id:2,name:'澄清答疑'},
	{id:3,name:'其他'},
	{id:4,name:'结果公告'},
	{id:5,name:'无'}
];

//公告编号
Vue.prototype.noticeNumber=[
	{id:1,code:'A0001',name:'服务类'},
	{id:2,code:'B0001',name:'货物类'},
	{id:3,code:'C0001',name:'工程类'},
	{id:4,code:'D0001',name:'其他类'},
];

//首页header默认轮播图
Vue.prototype.headerImg=[
	{src:require('@/assets/images/bk/bk1.jpg'),href:""},
	{src:require('@/assets/images/bk/bk2.jpg'),href:""},
	{src:require('@/assets/images/bk/bk3.jpg'),href:""},
]

Vue.prototype.headerImgMobile=[
	{src:require('@/assets/images/bk/bk1Mobile.jpg'),href:""},
	{src:require('@/assets/images/bk/bk2Mobile.jpg'),href:""},
	{src:require('@/assets/images/bk/bk3Mobile.jpg'),href:""},
]

Vue.prototype.issue_companys=[
	{id:1,name:'全国人大'},
	{id:2,name:'国务院'},
	{id:3,name:'财政部'},
	{id:4,name:'其他部委'},
	{id:5,name:'集采中心'},
	{id:6,name:'地方财政'},
	{id:7,name:'国务院办公厅'}
];

Vue.prototype.law_type_options= [{
		label: '采购主体类',
		value: '1000',
		options: [{
			value: '1001',
			label: '采购人'
		}, {
			value: '1002',
			label: '代理机构'
		}, {
			value: '1003',
			label: '评审专家'
		}, {
			value: '1004',
			label: '供应商'
		}]
	}, {
		label: '政策功能类',
		value: '2000',
		options: [{
			value: '2001',
			label: '节能环保'
		}, {
			value: '2002',
			label: '中小企业'
		}, {
			value: '2099',
			label: '其他'
		}]
	}, {
		label: '交易系统类',
		value: '3000',
		options: [{
			value: '3001',
			label: '采购计划'
		}, {
			value: '3002',
			label: '信息统计'
		}, {
			value: '3003',
			label: '项目采购'
		},{
			value: '3099',
			label: '其他'
		}]
	}, {
		label: '采购方式类',
		value: '4000',
		options: [{
			value: '4001',
			label: '公开招标'
		}, {
			value: '4002',
			label: '竞争性磋商'
		}, {
			value: '4003',
			label: '单一来源'
		}, {
			value: '4004',
			label: '非招标采购'
		},{
			value: '4099',
			label: '其他'
		}]
	}, {
		label: '其他文件类',
		value: '5000',
		options: [{
			value: '5001',
			label: '信息公开'
		}, {
			value: '5002',
			label: '行政处罚'
		}, {
			value: '5003',
			label: '公共资源'
		}, {
			value: '5004',
			label: '政府和社会资本合作'
		},{
			value: '5005',
			label: '会议培训'
		}, {
			value: '5006',
			label: '采购目录'
		}, {
			value: '5007',
			label: '信息化建设'
		}, {
			value: '5008',
			label: '政府购买服务'
		}, {
			value: '5009',
			label: '网站建设'
		},{
			value: '5099',
			label: '其他'
		}]
}];

	
	