import Vue from 'vue';
import axios from 'axios';
import ElementUI from 'element-ui';
import * as echarts from 'echarts'

import App from './App.vue';
import router from "./router/router.js";
import '../config/config.js';
import bus from'../src/assets/js/eventBus.js';
import 'babel-polyfill';
import 'es6-promise/auto';

import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
import '../src/assets/font/iconfont.css';
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
import 'tinymce/icons/default/icons'
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.prototype.bus = bus
Vue.use(ElementUI)
Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)


router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// if(to.meta.content){
	// 	let head = document.getElementsByTagName('head');
	// 	let meta = document.createElement('meta');
	// 	document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);
	// 	document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
	// 	meta.content = to.meta.content;
	// 	head[0].appendChild(meta)
	// }
  next()
})

Vue.config.productionTip = false
new Vue({
		router,
		render: (h) => h(App),
		mounted () {
			document.dispatchEvent(new Event('render-event')) // 需要加上
		}
}).$mount("#app");
