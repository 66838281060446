import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
	{
		path: "/timeBeijing",
		name: "timeBeijing",
		meta: { title: '招投研究院-北京时间' },
		component: () => import("../components/timeBeijing.vue")
	},
	{
		name: 'home',
		path: '/',
		meta: {
			title: '招投研究院',
			content: {
				keywords: '投标,招标,招投标,研究院,招标代理,标书代写,标书审核,招投标资质办理,招投标培训,招投研究院,软件开发',
				description: '招投研究院有限公司是一家专注于招标、投标整体能力提升的专业服务机构,为企业提供标书代写、标书制作、招投标培训全方位的招投标服务解决方案,快速提升中标率。',
			},
		},
		component: () => import("../views/home/index.vue"),
		//redirect: '/frontbody',
		children: [
			{
				name: 'aboutUs',
				path: '/aboutUs',
				meta: { title: '关于我们' },
				component: () => import("../views/home/aboutUs/aboutUs.vue"),
			},
			{
				name: 'companyActive',
				path: '/companyActive',
				meta: { title: '公司业务' },
				component: () => import("../views/home/companyActive/companyActive.vue"),
			},
			{
				name: 'tenderInfo',
				path: '/tenderInfo',
				meta: { title: '招标信息' },
				component: () => import("../views/home/tenderTotal/tenderInfo.vue"),
			},
			{
				name: 'tenderInfoContent',
				path: '/tenderInfoContent',
				meta: { title: '招标详情' },
				component: () => import("../views/home/tenderTotal/tenderInfoContent.vue"),
			},
			{
				name: 'tenderTotal',
				path: '/tenderTotal',
				meta: { title: '招标审计' },
				component: () => import("../views/home/tenderTotal/tenderTotal.vue"),
			},
			{
				name: 'tenderAgent',
				path: '/tenderAgent',
				meta: { title: '招标代理' },
				component: () => import("../views/home/tenderTotal/tenderAgent.vue"),
			},
			{
				name: 'tenderTrain',
				path: '/tenderTrain',
				meta: { title: '招投标培训' },
				component: () => import("../views/home/tenderTotal/tenderTrain.vue"),
			},
			{
				name: 'tenderTrainContent',
				path: '/tenderTrainContent',
				meta: { title: '课程详情' },
				component: () => import("../views/home/tenderTotal/tenderTrainContent.vue"),
			},
			{
				name: 'tenderResearch',
				path: '/tenderResearch',
				meta: { title: '招投标专题研究' },
				component: () => import("../views/home/tenderTotal/tenderResearch.vue"),
			},
			{
				name: 'tenderSultation',
				path: '/tenderSultation',
				meta: { title: '招投标咨询' },
				component: () => import("../views/home/tenderTotal/tenderSultation.vue"),
			},
			{
				name: 'police',
				path: '/police',
				meta: { title: '政策法规' },
				component: () => import("../views/home/police/searchPolicy.vue"),
			},
			{
				name: 'policeList',
				path: '/policeList',
				meta: { title: '政策法规-列表' },
				component: () => import("../views/home/police/policeList.vue"),
			},
			{
				name: 'policeLevel',
				path: '/policeLevel',
				meta: { title: '政策法规-法规层次' },
				component: () => import("../views/home/police/policeLevel.vue"),
			},
			{
				name: 'policeType',
				path: '/policeType',
				meta: { title: '政策法规-条法类别' },
				component: () => import("../views/home/police/policeType.vue"),
			},
			{
				name: 'policeIssue',
				path: '/policeIssue',
				meta: { title: '政策法规-法规文件' },
				component: () => import("../views/home/police/policeIssue.vue"),
			},
			{
				name: 'policeContent',
				path: '/policeContent',
				meta: { title: '法规详情' },
				component: () => import("../views/home/police/policeContent.vue"),
			},
			{
				name: 'news',
				path: '/news',
				meta: { title: '新闻中心' },
				component: () => import("../views/home/news/searchNews.vue"),
			},
			{
				name: 'newsContent',
				path: '/newsContent',
				meta: { title: '新闻详情' },
				component: () => import("../views/home/news/newsContent.vue"),
			},
			{
				name: 'professor',
				path: '/professor',
				meta: { title: '专家中心' },
				component: () => import("../views//home/professor/professorKnow.vue"),
			}, 
			{
				name: 'member',
				path: '/member',
				meta: { title: '人力资源' },
				component: () => import("../views//home/member/manage.vue"),
			}, 
		]
	},
	{
		path: "/institute",
		name: "institute",
		meta: { title: '官网后台' },
		component: () => import("../views/admin/login.vue")
	},
	{
		path: "/admins/index",
		name: "admins/index",
		meta: { title: '官网后台' },
		component: () => import("../views/admin/index.vue"),
		redirect: '/admins/news/list',//初始加载右侧页面
		children: [
			{
				path: "/admins/news/list",
				component: () => import("../views/admin/news/list.vue")
			},
			{
				path: "/admins/news/category",
				component: () => import("../views/admin/news/category.vue")
			},
			{
				path: "/admins/pdicies/list",
				component: () => import("../views/admin/pdicies/list.vue")
			},
			{
				path: "/admins/pdicies/category",
				component: () => import("../views/admin/pdicies/category.vue")
			},
			{
				path: "/admins/tenderInfo/tenderList",
				component: () => import("../views/admin/tenderInfo/tenderList.vue")
			},
			{
				path: "/admins/activity",
				component: () => import("../views/admin/activity.vue")
			},
			{
				path: "/admins/setting/carousel/list",
				component: () => import("../views/admin/setting/carousel/list.vue")
			},
		]
	},
	{
		name: 'mobile',
		path: '/mobile',
		meta: {
			title: '招投研究院',
			content: {
				keywords: '投标,招标,招投标,研究院,招标代理,标书代写,标书审核,招投标资质办理,招投标培训,招投研究院,软件开发',
				description: '招投研究院有限公司是一家专注于招标、投标整体能力提升的专业服务机构,为企业提供标书代写、标书制作、招投标培训全方位的招投标服务解决方案,快速提升中标率。',
			},
		},
		component: () => import("../views/mobile/index.vue"),
		redirect: '/mobile/home',
		children: [
			{
				name: '/mobile/home',
				path: '/mobile/home',
				meta: {
					title: '招投研究院',
					content: {
						keywords: '投标,招标,招投标,研究院,招标代理,标书代写,标书审核,招投标资质办理,招投标培训,招投研究院,软件开发',
						description: '招投研究院有限公司是一家专注于招标、投标整体能力提升的专业服务机构,为企业提供标书代写、标书制作、招投标培训全方位的招投标服务解决方案,快速提升中标率。',
					},
				},
				component: () => import("../views/mobile/home.vue"),
			},{
				name: '/mobile/register',
				path: '/mobile/register',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/register.vue"),
			},{
				name: '/mobile/registerSuccess',
				path: '/mobile/registerSuccess',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/registerSuccess.vue"),
			},{
				name: '/mobile/tender',
				path: '/mobile/tender',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tender.vue"),
			},{
				name: '/mobile/tenderContent',
				path: '/mobile/tenderContent',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderContent.vue"),
			},{
				name: '/mobile/companyActive',
				path: '/mobile/companyActive',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/companyActive.vue"),
			},{
				name: '/mobile/tenderAgent',
				path: '/mobile/tenderAgent',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderAgent.vue"),
			},{
				name: '/mobile/tenderSultation',
				path: '/mobile/tenderSultation',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderSultation.vue"),
			},{
				name: '/mobile/tenderTrain',
				path: '/mobile/tenderTrain',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderTrain.vue"),
			},{
				name: '/mobile/tenderResearch',
				path: '/mobile/tenderResearch',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderResearch.vue"),
			},{
				name: '/mobile/tenderTotal',
				path: '/mobile/tenderTotal',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/tenderTotal.vue"),
			},{
				name: '/mobile/aboutUs',
				path: '/mobile/aboutUs',
				meta: {
					title: '招投研究院',
				},
				component: () => import("../views/mobile/aboutUs.vue"),
			},
		]
	}
];
const router = new VueRouter({
	mode: "history",//hash模式下，浏览器地址不规整,带有#,history模式下，浏览器地址规整，但需要后台支持
	base: process.env.BASE_URL,
	routes
});
export default router;